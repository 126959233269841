@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-grey2;
}

.content {
  @apply bg-secondaryWhite px-5 py-3 shadow;
}
.icon-text > :first-child {
  @apply mr-2;
}

.icon-text-group {
  @apply mt-1.5 flex flex-wrap;
}

.icon-text-group div {
  @apply mr-5;
}

.icon-text-group div:last-child {
  @apply mr-0;
}

.messagebox {
  @apply text-xs p-3 rounded shadow bg-secondaryWhite;
}

.shadow-text {
  text-shadow: -1px -1px 0 #ffffff, 1px -1px 0 #ffffff, -1px 1px 0 #ffffff,
    1px 1px 0 #ffffff;
}
