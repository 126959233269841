.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary {
  @apply text-secondaryBlack text-base px-8;
}

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected {
  @apply font-extrabold text-secondaryBlack;
}

.MuiTabs-scroller.MuiTabs-fixed > span.MuiTabs-indicator {
  @apply bg-primaryGreen;
}

.MuiPaper-root.MuiPaper-elevation {
  @apply m-0;
}

input[type='time']::-webkit-calendar-picker-indicator {
  @apply bg-none hidden;
}
